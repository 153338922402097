import { MembershipStateEnum } from "../user/enum/membership-state.enum";

export const formatMembership = (number: string) => {
  const baseNumber: string = "00000";
  const lastString = baseNumber.substring(baseNumber.length, number.toString().length)
  const formatNumber =  lastString + number;
  return formatNumber;
}

export const formatMembershipStatus = (status:string) => {
  return status === MembershipStateEnum.ACTIVE ? "ACTIVO" : "INACTIVO";
}
