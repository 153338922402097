import { jwtDecode } from "jwt-decode";
import { UserType } from "../../../utils/user/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const useAdmin = () => {

  const navigate = useNavigate();
  const [useIsAdmin,  setIsAdmin] = useState<boolean>(false)
  
  const goToHome = () => {
    return navigate('/Home')
  }
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token){
      const tokenFormated = jwtDecode(token) as {sub:string, user:UserType};
      const {role} = tokenFormated.user;
      const isAdmin = role.find((r) => r === 'ADMIN')
      if(isAdmin !== undefined){
        setIsAdmin(true)
      }
    }
  },[])
    
  return{
    useIsAdmin,
    goToHome
  }

}