import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthFormErrorType } from "../../../utils/types/auth-form-error.type";
import { formatRut  } from "react-rut-formatter";

type RegisterType = {
  name: string;
  lastName: string;
  birthdate: Date; 
  dni: string;  
  address: string;
  email: string;
  password: string;
}

const init:RegisterType = {
  name: "",
  lastName: "",
  birthdate: new Date(),
  dni: "",
  address: "",
  email: "",
  password: ""
} 
const init_error:AuthFormErrorType = {
  state: false,
  message: "Ups!! Parece que hubo un error"
}

export const useRegister = () => {

  const navigate = useNavigate();
  const [ useRegister, setRegister ] = useState<RegisterType>(init);
  let requestOptions:RequestInit = {method: 'POST'};
  const [error, setError] = useState<AuthFormErrorType>(init_error);
  // const { rut, updateRut, isValid } = useRut();

  const fetchRegister = async () => {
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/auth/register`
    fetch(uri, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if(data.statusCode){
          setError({state:true, message:data.message});
          return;
        }
        return navigate('/Auth', {replace: true, state: useRegister.email});
      })
      .catch((err) => {
        setError({state:true, message:init_error.message});
        console.log(err.message);
      });
  }

  const handleSubmit  = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    requestOptions = {
      method: 'POST',
      headers: [["Content-Type", "application/json"]],
      body: JSON.stringify({...useRegister})
      };
    await fetchRegister()
  };
  
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setRegister((prevState) => ({ ...prevState, [name]: value}))
  }

  const onInputChangeDni = (e: ChangeEvent<HTMLInputElement>) =>{
    let target = e.target;
    let { value } = target;
    e.target.value = formatRut(value);
    onInputChange(e);
  }
  
  return{
    error,
    handleSubmit,
    onInputChange,
    onInputChangeDni
  }
}
