import { useAdminUserEdit } from "./hooks/user-edit.hook"



export const AdminUsereditView = () => {

  const { member, handleSubmit, onInputChange, onSelectChange, seeMember, messageBackEnd, closeAlert } = useAdminUserEdit()
  const { profile, role } = member;
  const { name, lastName, imageProfile, dni, birthdate, address, membership, membershipStatus } = profile

  const handleNotification = () => {
    if(messageBackEnd.state)
      return(
        <div className="form-notification">
          <div className={`alert alert-${messageBackEnd.type} d-flex justify-content-around`} role="alert">
            <i className="bi bi-exclamation-triangle"></i>
            <p className="text-center">{messageBackEnd.message}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={closeAlert}></button>
          </div>
        </div>
      )
    return(<></>)
  }

  return(
    <div className="container my-5">
      <div className="d-flex justify-content-between">
        <h2>Editar Usuario</h2>
        <button type="button" className="btn btn-outline-primary btn-sm mx-3" onClick={() => {seeMember()}} >Ver</button>
      </div>
      <hr />
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row g-3 py-4">
            <div className="col col-md-4 col-ms-6">
              <h5>Nombres</h5>
              <p>{name}</p>
              <input name={"name"} type="text" placeholder={name} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Apellidos</h5>
              <p>{lastName}</p>
              <input name={"lastName"} type="text" placeholder={lastName} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Imagen</h5>
              <p>{imageProfile}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Rut</h5>
              <p>{dni}</p>
              <input name={"dni"} type="text" placeholder={dni} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Fecha de Nacimiento</h5>
              <p>{birthdate}</p>
              <input name={"birthdate"} type="date" placeholder={birthdate} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Direccion</h5>
              <p>{address}</p>
              <input name={"address"} type="text" placeholder={address} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Numero de Socio</h5>
              <input name={"membership"} type="number" placeholder={membership ?? ""} onChange={onInputChange} />
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Estado</h5>
              <select name={"membershipStatus"} onChange={onSelectChange} >
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </select>
            </div>
          </div>
          <div>
            <button type={"submit"} className="btn btn-sm btn-outline-primary">Enviar</button>
          </div>
        </form>
      </div>
      <div className="p-5">
        {handleNotification()}
      </div>
    </div>
  )
}