import { UserType } from "../user/user";
import { MemberShipActionType } from "./types/membership-action.type";


export const memberShipReducer = (state:UserType[], action:MemberShipActionType): UserType[] => {

  switch(action.action){
    case "ALL":
      return {
        ...state
      };

    case "GET":
      const getFilter = action.payload
      const statefilered:UserType[] = []
      statefilered.push(state.find(({...s}) => s.profile.id === getFilter) as UserType);
      // const statefilered
      return {
        ...statefilered
      }

    case "UPDATE":
      const updateFilter = action.payload.id
      state.forEach(({ ...s }) => {
        if (s.profile.id === updateFilter)
          s = action.payload.membership;
        return s;
      })
      return {
        ...state
      }

    default:
      return state;
  }
}