import { useContext, useEffect, useState } from "react";
import { UserContext, UserProfileType } from "../../../utils/user/user";
import { useFetchService } from "../../../utils/functions/service.function";
import { MembershipStateEnum } from "../../../utils/user/enum/membership-state.enum";

const init: UserProfileType = {
  id: "",
  name: "",
  lastName: "",
  birthdate: "",
  dni: "",
  address: "",
  imageProfile: "",
  membership: "",
  membershipStatus: MembershipStateEnum.ACTIVE
}

export const useCarnet = () => {

  const { user, actions } = useContext(UserContext);
  const { fetchService, getImagFromService } = useFetchService();
  const [ profile, setProfile ] = useState<UserProfileType>(init);
  const [imagProfile, setImagProfile] = useState<string>();
  
  useEffect(()=>{
    (async () => {
      const profile = await fetchService('GET', "profile");
      const responseImagProfile = await getImagFromService('GET', "profile/image-profile");
      setImagProfile(responseImagProfile)
      setProfile(profile);
    })();
  }, [])

  return {
    user,
    imagProfile,
    profile
  }
}