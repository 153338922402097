import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserType } from "../../../../utils/user/user";
import { MembershipStateEnum } from "../../../../utils/user/enum/membership-state.enum";
import { useFetchService } from "../../../../utils/functions/service.function";

const init:UserType = {
  email: "",
  role: [],
  profile: {
    id: "",
    name: "",
    lastName: "",
    birthdate: "",
    dni: "",
    address: "",
    imageProfile: "",
    membership: "",
    membershipStatus: MembershipStateEnum.ACTIVE
  }
}


export const useAdminUserView = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [member, setMember] = useState<UserType>(init);
  const [imagProfile, setImagProfile] = useState<string>();
  const { getImagFromService } = useFetchService();
  
  useEffect(()=>{
    (async () => {
      setMember(location.state)
      const responseImagProfile = await getImagFromService('GET', `Admin/user-image/${location.state.profile.imageProfile}`);
      setImagProfile(responseImagProfile)
    })();
  },[])
  // location.state

  const editMember = () => {
    return navigate('/Home/Admin/Users/Edit', {state: member})
  }

  return{
    member,
    imagProfile,
    editMember
  }
}
