import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../../utils/user/user.context"
import { UserProfileType } from "../../../utils/user/user"
import { useNavigate } from "react-router-dom";
import { MembershipStateEnum } from "../../../utils/user/enum/membership-state.enum";
import { useFetchService } from "../../../utils/functions/service.function";

const init:UserProfileType = {
  id: "",
  name: "",
  lastName: "",
  birthdate: "",
  dni: "",
  address: "",
  imageProfile: "",
  membership: "",
  membershipStatus: MembershipStateEnum.ACTIVE
} 

export const useProfile = () => {

  const navigate = useNavigate();
  const { user, actions } = useContext(UserContext);
  const [ userState, setUser ] = useState<UserProfileType>(init);
  const [ imagProfile, setImagProfile ] = useState<string>();
  const { fetchService, getImagFromService } = useFetchService();


  useEffect(() => {
    (async () => {
      const response = await fetchService('GET', "profile");
      setUser(response);
      const responseImagProfile = await getImagFromService('GET', "profile/image-profile");
      setImagProfile(responseImagProfile)
    })();
  }, [])

  const goToEdit = () => {
    navigate('/Home/Profile/Edit', {state: userState});
  }
  
  return {
    userState,
    imagProfile,
    goToEdit
  }
}