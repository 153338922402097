
type MethodType = 'GET' | 'POST' | 'PUT'


export const useFetchService = () =>{

  const token:string = localStorage.getItem('token') as string;
  
  const fetchService = async (method: MethodType, endpoint: string, bodyParam?:any) => {
    const requestOptions:RequestInit = {
      method,
      headers:[
        ['Content-Type', 'application/json'],
        ['Authorization',`Bearer ${token}`]
      ],
      body: JSON.stringify(bodyParam) 
    };
    
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/${endpoint}`;
    return await fetch(uri, requestOptions)
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => {
        console.log(err.message);
      });
  }

  const getImagFromService = async (method: MethodType, endpoint: string, bodyParam?:any) => {
    const requestOptions:RequestInit = {
      method,
      headers:[
        ['Content-Type', 'image/jpeg'],
        ['Authorization',`Bearer ${token}`]
      ],
      body: JSON.stringify(bodyParam) 
    };
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/${endpoint}`;
    const response =  await fetch(uri, requestOptions)
      .then((response) => {
        return response
      })
      .then(async (data) => {
        if(!data.ok) 
          return '/default_carnet_img.jpg';
        const resp =  URL.createObjectURL(await data.blob());
        return resp;
      })
      .catch((err) => {
        // console.log(err.message);
        return;
      }) as string;
    return response;
  }
    
  const fetchFileService = async (method: MethodType, endpoint: string, bodyParam:File) => {
    const formData = new FormData();
    formData.append('profileImag', bodyParam);
    const requestOptions:RequestInit = {
      method,
      headers:[
        ['Authorization',`Bearer ${token}`]
      ],
      body: formData 
    };
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/${endpoint}`;
    return await fetch(uri, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        //console.log(err.message);
        return;
      });
  }

  return {
    fetchService,
    getImagFromService,
    fetchFileService
  }


  
}