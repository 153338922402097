import { useLogin } from "./hooks/login.hook";



export const LoginView = () => {

  const { error, closeAlert, handleSubmit, onInputChange } = useLogin();

  const handleNotification = () => {
    if(error.state)
      return(
        <div className="form-notification">
          <div className="alert alert-warning d-flex justify-content-around w-100" role="alert">
            <i className="bi bi-exclamation-triangle"></i>
            <p className="text-center">{error.message}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={()=>closeAlert()}></button>
          </div>
        </div>
      )
    return(<></>)
  }

  return(
    <form onSubmit={handleSubmit}>
      <div className="auth-form"> 
        <div className="form-header">
          <h2>Login</h2>
        </div>
        <div className="form-body">
          <div className="form-input">
            <label >Email</label>
            <input type="email" name="email" id="email" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Contraseña</label>
            <input type="password" name="password" id="password" placeholder="" onChange={onInputChange} required />
          </div>
          {handleNotification()}
          <button type="submit" className="form-submit">Enviar</button>
        
        </div>
        <div className="form-footer">
          <p>Registrate <a href="/Auth/Register">aqui</a></p>
          <p><a href="/Auth/Recovery">Recuperar tu Cuenta </a></p>
        </div>
      </div>
      <div className="register">
      </div>
    </form>
  )
}