


export const HomeView = () => {


return(
  <section className="container d-flex flex-column min-vh-100">
    <div className="container">
      <h2>Home</h2>
    </div>
  </section>
  )
}