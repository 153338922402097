import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/user/user.context";
import { AuthContext } from "../../utils/utils";
import { useAdmin } from "../../views/admin/hooks/index.hook";



export const useNavBarHome = () => {

  const { userState, action } = useContext(AuthContext)
  const { user, actions } = useContext(UserContext)
  const { name } = user;
  const imageProfile = ''
  const navigate = useNavigate();
  const {useIsAdmin} = useAdmin()

  useEffect(()=>{
    
  },[])

  const logOut = () => {
    action(); 
    navigate('/', {replace: true})
  }


  return{
    imageProfile, 
    useIsAdmin, 
    userState, 
    logOut
  }
}