import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MembershipStateEnum } from "../../../utils/user/enum/membership-state.enum";
import { UserProfileType } from "../../../utils/user/user";
import { useFetchService } from "../../../utils/functions/service.function";


const init:UserProfileType ={
  id: "",
  name: "",
  lastName: "",
  birthdate: "",
  dni: "",
  address: "",
  imageProfile: "",
  membership: "",
  membershipStatus: MembershipStateEnum.ACTIVE
} 

export const useEditProfile = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [profileState, setProfile ] = useState<UserProfileType>(init);
  const [imgProfile, setImgProfile] = useState<File>();
  const { fetchService, fetchFileService } = useFetchService()


  useEffect(() => {
    if(location.state)
      setProfile(location.state)
  }, [])
  
  const handleSubmit  = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    const response = await fetchService('PUT', 'profile/update');
    if(imgProfile){
      await uploadImgProfile();
    }
    setProfile(response);
  };
  
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setProfile((prevState) => ({ ...prevState, [name]: value}))
  }

  const uploadImgProfile = async () => {
    const response = await fetchFileService('PUT', 'profile/update-file', imgProfile as File);
    setProfile(response);
  }

  const onFileLoad = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const { files } = target;
    if(files){
      setImgProfile(files[0])
    }
  }
  
  return {
    profileState,
    handleSubmit,
    onInputChange,
    onFileLoad
  }
}
