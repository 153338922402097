import { useNavBarHome } from "./hooks/nav-var-home.hook";


export const NavBarHome = () => {

  const expand = 'md';
  const { imageProfile, useIsAdmin, userState, logOut } = useNavBarHome()
  
  
  const imgProfileFn = () => {
    if(imageProfile)
      return <img src={imageProfile} alt="hugenerd" width="30" height="30" className="rounded-circle" />;
    return <i className="bi bi-person-circle"></i>
  }
  
  const adminMenu = () => {
    if(useIsAdmin){
      return(
        <li>
          <a href="#submenuAdmin" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
            <i className="fs-4 bi-bar-chart-line"></i> <span className="ms-1 d-none d-sm-inline">Admin</span></a>
          <ul className="collapse nav flex-column ms-1" id="submenuAdmin" data-bs-parent="#menu">
            <li className="w-100">
              <a href="/Home/Admin/Users" className="nav-link px-0"> <span className="d-none d-sm-inline">Usuarios</span></a>
            </li>
            <li>
              <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
            </li>
          </ul>
        </li>
      )
    }
    return(<></>)
  }
  
  return (
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark bg-gradient">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a href="/Home" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <span className="fs-5 d-none d-sm-inline">Menu</span>
        </a>
        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li className="nav-item">
            <a href="/Home" className="nav-link align-middle px-0">
              <i className="fs-4 bi-house"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
            </a>
          </li>
          {adminMenu()}
          <li>
            <a href="/Home/Carnet" className="nav-link px-0 align-middle">
              <i className="fs-4 bi-person-vcard"></i> <span className="ms-1 d-none d-sm-inline">Carnet</span></a>
          </li>
          <li>
            <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
              <i className="fs-4 bi-bar-chart-line"></i> <span className="ms-1 d-none d-sm-inline">Estadisticas</span></a>
            <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
              <li className="w-100">
                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1</a>
              </li>
              <li>
                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
              </li>
            </ul>
          </li>
        </ul>
        <hr />
        <div className="dropdown pb-4">
          <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            {imgProfileFn()}
            <span className="d-none d-sm-inline mx-1">{userState && userState.login === 'AUTHENTICATED' ? userState.name : 'User'}</span>
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
            {/* <li><a className="dropdown-item" href="#">New project...</a></li> */}
            <li><a className="dropdown-item" href={"/Home/Settings"}>Settings</a></li>
            <li><a className="dropdown-item" href={"/Home/Profile"}>Profile</a></li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li><a className="dropdown-item" href="#" onClick={()=>{logOut()}}>Sign out</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}