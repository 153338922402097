import { formatMembership, formatMembershipStatus } from "../../utils/functions/membership.function";
import { useCarnet } from "./hooks/carnet.hook"


export const CarnetView = () => {

  const { profile, imagProfile } = useCarnet()
  const { name, lastName, dni, birthdate, imageProfile, membership, membershipStatus } = profile;
  
  return(
    <div className="container d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <div className="carnet">
        <img src="/logo-icon1.png" className="bg-img" alt="bg-img" />
        <div className="title">
          <img src="/logo-icon1.png" className="club-icon" alt="profile-img" />
          <p>Club Social & Deportivo Argentino</p>
        </div>
        <div className="carnet-content">
          <div className="img-section">
            <img src={imagProfile} />
            <div className="rut-section">
              Rut: {dni}
            </div>
          </div>
          <div className="profile-section">
            <div className="profile-content">
              <p><strong>NOMBRE:</strong> {name}</p>
              <p><strong>APELLIDO:</strong> {lastName}</p>
              <p><strong>FECHA NAC.:</strong> {birthdate}</p>
              <p><strong>N° DE SOCIO:</strong> {membership ? formatMembership(membership) : "-"}</p>
              <p><strong>Estado:</strong> <strong>{formatMembershipStatus(membershipStatus)}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
