import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../../utils/user/user";
import { MemberShipContext } from "../../../../utils/memberships/membership.contex";


export const useAdminUsers = () => {

  const { memeberships, memebershipsAction } = useContext(MemberShipContext);
  const [ useError, setError ] = useState<{state:boolean, message: string}>({state:false, message:""});
  const navigate = useNavigate();
  
  const refreshList = async () => {
    
  }

  const seeMember = (member: UserType) => {
    return navigate('/Home/Admin/Users/View', {state: member})
  }
  
  const editMember = (member: UserType) => {
    return navigate('/Home/Admin/Users/Edit', {state: member})
  }

  return{
    memeberships,
    useError,
    refreshList,
    seeMember,
    editMember
  }
}