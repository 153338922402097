import { formatMembership } from "../../../utils/functions/membership.function"
import { useAdminUsers } from "./hooks/users.hook"


export const AdminUsersView = () => {

  const {memeberships, useError, refreshList, seeMember, editMember} = useAdminUsers()

  const tableBody = () => {
    if(memeberships && memeberships.length>0){
      return(
        memeberships.map((u, i) => {
          if(u) {
            const {name, lastName, membership, membershipStatus} = u.profile;
            return(
              <tr key={i}>
                <th>{i++}</th>
                <td>{name}</td>
                <td>{lastName}</td>
                <td>{membership !== null ? formatMembership(membership) : '-'}</td>
                <td>{membershipStatus}</td>
                <td >
                  <div className="d-flex justify-content-evenly">
                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => seeMember(u)} >Ver</button>
                    <button type="button" className="btn btn-outline-warning btn-sm" onClick={() => editMember(u)} >Editar</button>
                  </div>
                </td>
              </tr>
            )
          }
        })
      )
    }
    return(<></>)
  }

  return(
    <div className="container my-5">
      <div className="d-flex justify-content-between">
        <h2>Admin Usuarios</h2>
        <button type={"button"} className="btn btn-outline-secondary" onClick={refreshList}><i className="bi bi-arrow-repeat"></i></button>
      </div>
      <hr />
      <div className="container">
        <div className="table-responsive">
          <table className="table table-sm table-info table-striped table-hover align-middle">
            <thead className="m-3">
              <tr >
                <th scope="col" className="mx-4">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">N° Soc.</th>
                <th scope="col">Estado</th>
                <th scope="col col-sm">Acciones</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {tableBody()}
            </tbody>
          </table>
          {useError.message}
        </div>
      </div>
    </div>
  )
}