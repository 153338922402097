import { useContext } from "react";
import { Button, Container, Dropdown, Nav, NavDropdown, NavItem, NavLink, Navbar, Offcanvas } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/auth/auth";
import { UserContext } from "../utils/user/user.context";


export const NavBarComponent = () => {

    const expand = 'md';
    const { userState, action } = useContext(AuthContext)
    const { user, actions } = useContext(UserContext)
    const navigate = useNavigate();

    const userManu = () => {
      // const { login } = userState;
      if(userState && userState.login === 'AUTHENTICATED'){
        return(
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>{user.name}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {navigate('/Home')}}>Profile</Dropdown.Item>
              <Dropdown.Item onClick={() => {navigate('/Home/Carnet')}}>Carnet</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => {action()}}>Log-Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      }else{
        return(
          <>
            <Nav.Link href="/Auth">Login</Nav.Link>
            <div className="vr" />
            <Nav.Link href="/Auth/Register">Register</Nav.Link>
          </>
        )
      }
    }

    return(
        <Navbar key={expand} expand={expand} className="header fixed-top d-flex align-items-center">
          <Container fluid>
            <Navbar.Brand className="brand-nav logo" href="/">
              <img src="/logo-icon1.png" alt="logo-icon" />
              CS&DA
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >

              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#hero">Home</Nav.Link>
                  <Nav.Link href="#">Quienes Somos</Nav.Link>
                  <Nav.Link href="#">Eventos</Nav.Link>
                  <Nav.Link href="#">Contacto</Nav.Link>
                </Nav>
                
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {userManu()}
                </Nav>
              </Offcanvas.Body>
              
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        )
} 