import { formatMembership } from "../../../utils/functions/membership.function";
import { useAdminUserView } from "./hooks/user-view.hook"



export const AdminUserViewView = () => {

  const { member, imagProfile, editMember } = useAdminUserView()
  const { profile, role } = member;
  const { name, lastName, imageProfile, dni, birthdate, address, membership, membershipStatus } = profile


  return(
    <div className="container my-5">
      <div className="d-flex justify-content-between">
        <h2>Ver Usuario</h2>
        <button type="button" className="btn btn-outline-warning btn-sm mx-3" onClick={() => {editMember()}} >Editar</button>
      </div>
      <hr />
        <div className="container">
          <div className="row g-3">
            <div className="col col-md-4 col-ms-6">
              <h5>Nombres</h5>
              <p>{name}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Apellidos</h5>
              <p>{lastName}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Imagen</h5>
              <img src={imagProfile} style={{maxWidth: '150px'}} />
              {/* <p>{imageProfile}</p> */}
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Rut</h5>
              <p>{dni}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Fecha de Nacimiento</h5>
              <p>{birthdate}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Direccion</h5>
              <p>{address}</p>
              {/* <EditableText props={{initialText: address}} onInputChange={onInputChange}/> */}
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Numero de Socio</h5>
              <p>{membership ? formatMembership(membership) : '-'}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Estado</h5>
              <p>{membershipStatus}</p>
            </div>
          </div>
        </div>
    </div>
  )

}