import { UserActionType } from "./type/action.type";
import { UserStateType } from "./type/state.type";


export const userReducer = (state: UserStateType, action: UserActionType) => {

  switch(action.action) {
    case 'GET':
      return { ...state};
      
    case 'UPDATE':
      return { ...state, ...action.payload};  

    default:
      return state;
  }

}