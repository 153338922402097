import { ChangeEvent, FormEvent, useState } from "react";
import { useFetchService } from "../../../utils/functions/service.function"

type changePasswordType = {
  oldPassword: string;
  newPassword: string;
}

type SettingsAlert = {
  target: 'password-change'|'other';
  message: string;
  type: 'success'|'warning'|'danger';
  status: boolean
}

const changePasswordInit:changePasswordType = {
  oldPassword: "",
  newPassword: ""
}

const alertInit:SettingsAlert = {
  target: "password-change",
  message: "",
  type: "success",
  status: false
}

export const useSettings = () => {

  const [chagePassword, setChangePassword] = useState<changePasswordType>(changePasswordInit)
  const [alert, setAlert] = useState<SettingsAlert>(alertInit)
  const { fetchService } = useFetchService()

  const handleSubmitPasswordChange = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if(chagePassword.oldPassword !== chagePassword.newPassword){
      const response = await fetchService('POST', "auth/update-password", chagePassword);
      if(response.error)
        return setAlert({status: true, target: "password-change", type: "danger", message: response.message})
      return setAlert({status: true, target: "password-change", type: "success", message: response.message})
    }
    return setAlert({status: true, target: "password-change", type: "warning", message: "La contraseña deben ser distintas"})
  }
  
  const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setChangePassword((prevState) => ({ ...prevState, [name]: value}))
  }
  
  const closeAlert = () => {
    setAlert((prevState) => ({ ...prevState, status: false}))
  }

  return{
    handleSubmitPasswordChange,
    onPasswordInputChange,
    alert,
    closeAlert
  }
}