import { Container } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import { FooterComponent } from "../components/components"
import { AuthProvider } from "../utils/auth/auth"

import './css/auth.css'
import './css/footer.css'


export const AuthTemplate = () => {

  return(
    <div className="auth-container ">
      <Container fluid={"md"} className="d-flex flex-column min-vh-100 justify-content-center align-items-center" >
        <div className="border-container">
          <AuthProvider children={<Outlet />} />
        </div>
      </Container>
      <FooterComponent />
    </div>
  )
}