import { useState, useContext, FormEvent, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../utils/utils";
import { AuthFormErrorType } from "../../../utils/types/auth-form-error.type";

type UserType = {
  user: string;
  email: string;
}

const init_error:AuthFormErrorType = {
  state: false,
  message: "Ups!! Parece que hubo un error"
}

export const useLogin = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [ loginState, setLogin ] = useState<{email:string,password:string}>({email: location.state ? location.state : '', password:''});
  const [user, setUser] = useState<UserType>();
  const [error, setError] = useState<AuthFormErrorType>(init_error);
  const { userState, action } = useContext(AuthContext);
  let requestOptions:RequestInit = {method: 'POST'};

  const fetchLogin = async () => {
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/auth/singIn`
    fetch(uri, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if(data.status && data.status === 400){
          setError({state:true, message:data.message});
          return;
        }
        const {user, email, payload} = data;
        action(user, email, payload);
        setError({state:false, message:""});
        return navigate('/Home', {replace: true});
      })
      .catch((err) => {
          console.log(err.message);
          setError({state:true, message:init_error.message});
          return;
      });
  }

  const handleSubmit  = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    requestOptions = {
      method: 'POST',
      headers: [["Content-Type", "application/json"]],
      body: JSON.stringify({...loginState})
    };
    await fetchLogin()
  };

  
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setLogin((prevState) => ({ ...prevState, [name]: value}))
  }

  const closeAlert = () => {
    setError({state:false, message:error.message});
  }
  
  return{
    error,
    closeAlert,
    handleSubmit,
    onInputChange
  }
}