import { useReducer } from "react"
import { AuthContext } from "./auth.context"
import { StateType } from "./types/state.type"
import { authReducer } from "./auth.reducer"
import { ActionType, PayloadActionType } from "./types/action.type"
import { ChildrenProp } from "../types/chidre-provider.type"
import { jwtDecode } from "jwt-decode"
import { UserType } from "../user/user"
import { TokenType, TokenUserType } from "./types/token.type"

const INIT_STATE = {} as StateType


const init = ():StateType => {
  const token = localStorage.getItem('token') as string;
  if(token){
    const tokenFormated = jwtDecode(token) as TokenType;
    let userFormated:TokenUserType = tokenFormated.user;
    return {
      userState: {login: 'AUTHENTICATED', name: userFormated.name, email: userFormated.email},
      action: () => {}
    }
  }
  return {
      userState: {login: 'UNAUTHENTICATED'},
      action: () => {}
    }
}


export const AuthProvider = ({children}:ChildrenProp) => {

  const [authState, authDispatch] = useReducer(authReducer, INIT_STATE, init);

  const login = (name:string, email:string, token:string) => {
    const user:PayloadActionType = {name, email};
    const action:ActionType = {action: 'LOGIN', payload: user};

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);

    authDispatch(action)
  }
  
  const logout = () => {
    localStorage.clear()
    const action:ActionType = {action: 'LOGOUT' };
    authDispatch(action);
  }
  const actionState = authState.userState.login === 'UNAUTHENTICATED' ? login : logout;
  
  return(
    <AuthContext.Provider value={{
      ...authState,
      action: actionState
    }}>
      {children}
    </AuthContext.Provider>
  )
}