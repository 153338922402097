import { useReducer } from "react"
import { ChildrenProp } from "../types/chidre-provider.type"
import { UserStateType, UserType } from "./type/state.type"
import { UserContext } from "./user.context"
import { userReducer } from "./user.reducer"
import { jwtDecode } from 'jwt-decode'
import { MembershipStateEnum } from "./enum/membership-state.enum"
import { formatMembership } from "../functions/membership.function"
import { TokenType, TokenUserType } from "../auth/types/token.type"

const INIT_STATE:UserStateType = {
  user: {
    name: "",
    email: "",
    role: [""]
  },
  actions: () => {}
}

const init = () => {
  const token:string = localStorage.getItem('token') as string;
  if(token){
    const tokenFormated = jwtDecode(token) as TokenType;
    let userFormated:TokenUserType = tokenFormated.user;

    return {
      user: userFormated, 
      actions: () => {}
    } as const;
  }

  return INIT_STATE;
}


export const UserProvider = ({children}: ChildrenProp) => {
  
  const [userState, userDispatch] = useReducer(userReducer, INIT_STATE, init);

  const viewUser = () => {
    userDispatch({action: "GET"})
  }

  const updateUser = (user: Partial<TokenUserType>) => {
    userDispatch({action: "UPDATE", payload: user})
  }

  return(
    <UserContext.Provider value={
      {...userState, actions: viewUser}
      }>
      {children}
    </UserContext.Provider>
  )
}