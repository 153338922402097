import { Slide } from "react-slideshow-image";



export const HeroSection = () => {

  const slideImages:string[] = ['hero_1', 'hero_2', 'hero_3']

  const swipImg = (n:number) => {
  }

  return(
    <section className="hero">

      <img src="/hero_3.jpg" alt="" data-aos="fade-in" className="" />

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 data-aos="fade-up" data-aos-delay="100">Club Social & Deportivo Argentino</h2>
            <p data-aos="fade-up" data-aos-delay="200">Algun slogan del club.</p>
          </div>
        </div>
      </div>
      
    </section>
  )
}