import { useRecovery } from "./hooks/recovery.hook"



export const RecoveryView = () => {

  const { error, handleSubmit, onInputChange} = useRecovery()

  const handleNotification = () => {
    if(error.state)
      return(
        <div className="form-notification">
          <div className="alert alert-warning d-flex justify-content-around" role="alert">
            <i className="bi bi-exclamation-triangle"></i>
            <p className="text-center">{error.message}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      )
    return(<></>)
  }
  
  return(
    <form onSubmit={handleSubmit}>
      <div className="auth-form"> 
        <div className="form-header">
          <h2>Recuperar Cuenta</h2>
        </div>
        <div className="form-body">
          <div className="form-input">
            <label >Rut</label>
            <input type="text" name="rut" id="rut" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Email</label>
            <input type="email" name="email" id="email" placeholder="" onChange={onInputChange} required />
          </div>
          {handleNotification()}
          <button type="submit" className="form-submit">Enviar</button>
        </div>
        <div className="form-footer">
          <p>No tienes cuenta?</p> Ingresa <a href="/Auth/Register">aqui</a> 
        </div>
      </div>
    </form>
  )
}