import { useReducer } from "react";
import { ChildrenProp } from "../types/chidre-provider.type";
import { UserType } from "../user/user";
import { memberShipReducer } from "./membership.reducer";
import { MemberShipContext } from "./membership.contex";

const INIT_STATE:UserType[] = []


const init = ():UserType[] => {
  const membershipsStorage:string = localStorage.getItem('memberships') as string;
  if(membershipsStorage) 
    return JSON.parse(membershipsStorage) as UserType[];

  return INIT_STATE;
}


export const MemberShipProvider = ({children}: ChildrenProp) => {
  
  const [memeberships, membershipDispatch] = useReducer(memberShipReducer, INIT_STATE, init);

  const getMemberShips = () => {
    membershipDispatch({action: "ALL"})
  }

  const getMemberShip = (id: string) => {
    membershipDispatch({action: "GET", payload: id})
  }

  const updateMemberShip = (id: string, membership:UserType) => {
    membershipDispatch({action: "UPDATE", payload: {id, membership}})
  }
  
  return(
    <MemberShipContext.Provider 
      value={{
        memeberships,
        memebershipsAction: {
          all: getMemberShips,
          get: getMemberShip,
          update: updateMemberShip
        }
      }}
    >
      {children}
    </MemberShipContext.Provider>
  )

}