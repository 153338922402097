import { formatMembership, formatMembershipStatus } from "../../utils/functions/membership.function";
import { MembershipStateEnum } from "../../utils/user/enum/membership-state.enum";
import { useEditProfile } from "./hooks/profil-edit.hook"





export const ProfileEditView = () => {
  
  const { profileState, handleSubmit, onFileLoad} = useEditProfile()
  const { name, lastName, dni, birthdate, address, membership, membershipStatus, imageProfile } = profileState;

  return(
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <div className="mycard">
        <div className="mycard-title">
          <h2>Mi Perfil</h2>
        </div>
        <div className="mycard-body">
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col col-md-4 col-ms-6">
              <h5>Nombres</h5>
              <p>{name}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Apellidos</h5>
              <p>{lastName}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Imagen</h5>
              <img src={imageProfile} style={{maxWidth: '150px'}} />
              <input type="file" name="profileImag" onChange={onFileLoad} multiple={false}/>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Rut</h5>
              <p>{dni}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Fecha de Nacimiento</h5>
              <p>{birthdate}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Direccion</h5>
              <p>{address}</p>  
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Numero de Socio</h5>
              <p>{membership?formatMembership(membership):"-"}</p>
            </div>
            <div className="col col-md-4 col-ms-6">
              <h5>Estado</h5>
              <p>{formatMembershipStatus(membershipStatus)}</p>
            </div>
          </div>
          <div className="mycard-footer">
            <div className="container">
              <button className="btn btn-outline-warning btn-sm" type="submit">Enviar</button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  )
}