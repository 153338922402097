import { useSettings } from "./hooks/setting.hook"



export const SettingsView = () => {

  const {handleSubmitPasswordChange, onPasswordInputChange, alert, closeAlert} = useSettings()

  const alertChangePassword = () => {
    if(alert.target === "password-change" && alert.status){
      return(
        <div className="form-notification">
          <div className={`alert alert-${alert.type} d-flex justify-content-around`} role="alert">
            <i className="bi bi-exclamation-triangle"></i>
            <p className="text-center">{alert.message}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={closeAlert}></button>
          </div>
        </div>
      )
    }
    return(
      <>
      </>
    )
  }

  return(
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <div className="mycard">
        <div className="mycard-title">
          <h2>Settings</h2>
        </div>
        <div className="mycard-body">
          <form onSubmit={handleSubmitPasswordChange}>
            <div className="row g-3">
              <h5>Cambio contraseña:</h5>
              <div className="col col-md-4 col-ms-6">
                <input type="password" name="oldPassword" placeholder="Contraseña Actual" onChange={onPasswordInputChange}></input>
              </div>
              <div className="col col-md-4 col-ms-6">
                <input type="password" name="newPassword" placeholder="Contraseña Nueva" onChange={onPasswordInputChange}></input>
              </div>
              <div className="col col-md-4 col-ms-6">
                <button type="submit" className="btn btn-outline-primary btn-sm">Actualizar</button>
              </div>
            </div>
            <div className="row py-3 mx-5">
              {alertChangePassword()}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}