import { Container } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import { FooterComponent } from "../components/footer.component"
import { NavBarComponent } from "../components/nav-bar-index"
import { AuthProvider } from "../utils/auth/auth.provider"
import {  UserProvider } from "../utils/user/user"



export const IndexTemplate = () => {


  return(
    <AuthProvider children={
      <UserProvider children={
        <>
          <NavBarComponent />
          <Container fluid={"md"} className="d-flex flex-column min-vh-100 justify-content-center align-items-center" >
            <AuthProvider children={
              <UserProvider children={
                <Outlet />
              } />
            } />
          </Container>
          <FooterComponent />  
        </>
      }/>
    }/>
  )
}