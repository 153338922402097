import { Outlet } from "react-router-dom"
import { useAdmin } from "./hooks/index.hook"
import { useEffect } from "react"



export const AdminIndexview = () => {

  return(
    <>
      <Outlet />
    </>
  )
}