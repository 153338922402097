import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AdminUsersTemplate, AuthTemplate, HomeTemplate, IndexTemplate } from "./templates/templates";
import { AdminIndexview, AdminUserViewView, AdminUsereditView, AdminUsersView, CarnetView, HomeView, IndexView, LoginView, NotFoundview, ProfileEditView, ProfileView, RecoveryView, RegisterView, SettingsView } from "./views/views";
import './App.css';


function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>

          <Route path='/Auth' element={<AuthTemplate />} >          
            <Route index element={<LoginView />} />
            <Route path='Register' element={<RegisterView />} />
            <Route path='Recovery' element={<RecoveryView />} />
          </Route>

          <Route path='/' element={<IndexTemplate />} >
            <Route index element={<IndexView />} />
          </Route>

          <Route path='/Home' element={<HomeTemplate />} >
            <Route index element={<HomeView />} />
            <Route path='Carnet' element={<CarnetView />} />
            <Route path='Settings' element={<SettingsView />} />
            <Route path='Profile' element={<Outlet />}>
              <Route index element={<ProfileView />} />
              <Route path="Edit" element={<ProfileEditView />} />
            </Route>
            
            <Route path="Admin" element={<AdminIndexview />}>
              <Route path='Users' element={<AdminUsersTemplate />} >
                <Route index element={<AdminUsersView />} />
                <Route path='View' element={<AdminUserViewView />} />
                <Route path='Edit' element={<AdminUsereditView />} />
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<NotFoundview />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
