import { useRegister } from "./hooks/register.hook"



export const RegisterView = () => {

  // <form
  //
  const { error, handleSubmit, onInputChange, onInputChangeDni } = useRegister()

  const handleNotification = () => {
    if(error.state)
      return(
        <div className="form-notification">
          <div className="alert alert-warning d-flex justify-content-around" role="alert">
            <i className="bi bi-exclamation-triangle"></i>
            <p className="text-center">{error.message}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      )
    return(<></>)
  }
  
  return(
    <form onSubmit={handleSubmit} >
      <div className="auth-form"> 
        <div className="form-header">
          <h2>Register</h2>
        </div>
        <div className="form-body">
          <div className="form-input">
            <label >Nombres</label>
            <input type="text" name="name" id="name" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Apellidos</label>
            <input type="text" name="lastName" id="lastName" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Fecha de Nacimiento</label>
            <input type="date" name="birthdate" id="birthdate" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Rut</label>
            <input type="text" name="dni" id="dni" placeholder="" onChange={onInputChangeDni} required />
          </div>
          <div className="form-input">
            <label >Domicilio</label>
            <input type="text" name="address" id="address" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Email</label>
            <input type="email" name="email" id="email" placeholder="" onChange={onInputChange} required />
          </div>
          <div className="form-input">
            <label >Contraseña</label>
            <input type="password" name="password" id="password" placeholder="" onChange={onInputChange} required />
          </div>
          {handleNotification()}
          <button type="submit" className="form-submit">Enviar</button>
        </div>
        <div className="form-footer">
          <p>Ya tienes cuenta? Ingresa <a href="/Auth">aqui</a> </p> 
        </div>
      </div>
    </form>
  )
}