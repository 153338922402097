import { Container } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"
import { FooterComponent } from "../components/footer.component"
import { AuthProvider } from "../utils/auth/auth.provider"
import { NavBarHome } from "../components/nav-bar-home"
import { useEffect } from "react"

import "./css/carnet.css";
import { UserProvider } from "../utils/user/user.provider"

export const HomeTemplate = () => {

  const navigate = useNavigate();
  
  useEffect(() => {
    // Cambiar a token cuando tenga endpont de login
    const token = localStorage.getItem('token');
    if(!token)
      navigate('/');
  },[])
  
  return(
    <AuthProvider children={
      <UserProvider children={
        <>
          <div className="container-fluid">
            <div className="row flex-nowrap">
              <NavBarHome />
              <div className="col py-3">
                <AuthProvider children={
                  <UserProvider children={
                    <Outlet />
                  } />
                } />
              </div>
            </div>
          </div>
          <FooterComponent />
        </>
      } />
    }/>
  )
}