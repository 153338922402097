

export const FooterComponent = () => {


  return(
    <footer className="footer" >
      
      <div className="container footer-top">
        <div className="row gy-4">

          <div className="col-lg-5 col-md-12 footer-about">
            <a className="logo d-flex align-items-center" href="/">
              <img src="/logo-icon1.png" alt="logo-icon" />
              <span>CS&DA</span>
            </a>
          </div>

          <div className="col-lg-2 col-6 footer-links">

          </div>
          
          <div className="col-lg-2 col-6 footer-links">
            <h4>Contáctanos</h4>
            {/* <p>Prov Nte N°2 LT 1, Talca</p>
            <p>Santiago</p>
            <p>Chile</p> */}
            <p className="mt-4"><strong>Teléfono:</strong> <span><a href="https://wa.me/56949232837" target="_blank" rel="noopener">+56 9 4923 2837</a></span></p>
            <p><strong>Email:</strong> <span>presidencia@clubsydargentino.cl</span></p>
          </div>
        
        </div>
      </div>
    </footer>
  )
}