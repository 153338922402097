import { HeroSection } from "./sections/section"



export const IndexView = () => {


  return(
    <>
     <HeroSection />
    </>
  )
}