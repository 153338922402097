import { Outlet } from "react-router-dom"
import { MemberShipProvider } from "../utils/memberships/memebership.provider"
import { useEffect } from "react"
import { useFetchService } from "../utils/functions/service.function"
import { UserType } from "../utils/user/user"



export const AdminUsersTemplate = () => {

  const { fetchService } = useFetchService()

  useEffect(()=>{
    (async ()=>{
      const memberships = await fetchService('GET', "Admin/users");
      if(!memberships.error){
        const membersJson = JSON.stringify(memberships);
        localStorage.setItem('memberships', membersJson);
      }
    })()
  },[])

  return (
    <MemberShipProvider children={<Outlet />} />
  )
}