import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MembershipStateEnum } from "../../../../utils/user/enum/membership-state.enum";
import { UserType } from "../../../../utils/user/user";
import { BodyUpdateProfileMemberShipType, UpdateProfileMemberShipType } from "../../../../utils/types/admin/update-profile.type";
import { useFetchService } from "../../../../utils/functions/service.function";
import { MemberShipContext } from "../../../../utils/memberships/membership.contex";


const init:UserType = {
  email: "",
  role: [],
  profile: {
    id: "",
    name: "",
    lastName: "",
    birthdate: "",
    dni: "",
    address: "",
    imageProfile: "",
    membership: "",
    membershipStatus: MembershipStateEnum.ACTIVE
  }
}

const memberUpdatedInit: UpdateProfileMemberShipType = {
  name: "",
  lastName: "",
  birthdate: "",
  address: "",
  membership: null,
  membershipStatus: MembershipStateEnum.ACTIVE
}

export const useAdminUserEdit = () => {
  
  const [member, setMember] = useState<UserType>(init);
  const [memberUpdated, setMemberUpdated] = useState<UpdateProfileMemberShipType>(memberUpdatedInit);
  
  const { memeberships, memebershipsAction } = useContext(MemberShipContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [messageBackEnd, setMessageBackEnd] = useState<{message:string, state:boolean, type: 'success'|'danger'}>({message: "", state:false, type:'success'});
  const { fetchService } = useFetchService()
  
  useEffect(() => {
    setMember(location.state ?? init);
    setMemberUpdated({
      name: location.state.profile.name,
      lastName: location.state.profile.lastName,
      birthdate: location.state.profile.birthdate,
      address: location.state.profile.address,
      membership: location.state.profile.membership,
      membershipStatus: location.state.profile.membershipStatus
    });
  }, [])
  
  
  const handleSubmit  = async (event: FormEvent<HTMLFormElement>) => {
    const bodyRequest:BodyUpdateProfileMemberShipType = {
      profileId: member.profile.id,
      memberShip: memberUpdated
    }
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    

    // FIX SETMEMBER
    const response = await fetchService('PUT', 'Admin/users', bodyRequest)
    if(!response.error){
      memebershipsAction.update(response.id, {...member, profile:response})
      memeberships.find(m => m.profile.id = member.profile.id )
      setMember((prevent) => ({...prevent,  memeberships}))
      setMessageBackEnd({type:"success", state:true, message:"Usuario actualizado exitosamente"})
      return;
    }
    setMessageBackEnd({type:"danger", state:true, message:"No pudimos actualizar al usuario"})
    return;
    // memebershipsAction.get(response.id)
  };

  
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setMemberUpdated((prevState) => ({ ...prevState, [name]: value}))
  }
  
  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) =>{
    const target = event.target;
    const { name, value } = target;
    setMemberUpdated((prevState) => ({ ...prevState, [name]: value}))
  }

  const seeMember = () => {
    return navigate('/Home/Admin/Users/View', {state: member})
  }

  const closeAlert = () => {
    setMessageBackEnd((prevState) => ({ ...prevState, state: false}))
  }

  return{
    member,
    handleSubmit,
    onInputChange,
    onSelectChange,
    seeMember,
    messageBackEnd,
    closeAlert
  }
}
