import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringLiteral } from "typescript";
import { AuthFormErrorType } from "../../../utils/types/auth-form-error.type";


type RecoveryType = {
  rut: string;
  email: string;
}

const init:RecoveryType = {
  rut: "",
  email: ""
}

const init_error:AuthFormErrorType = {
  state: false,
  message: "Ups!! Parece que hubo un error"
}


export const useRecovery = () => {

  const navigate = useNavigate();
  const [useRecovery, setRecovery] = useState<RecoveryType>(init);
  let requestOptions:RequestInit = {method: 'POST'};
  const [error, setError] = useState<AuthFormErrorType>(init_error);

  const fetchRecovery = async () => {
    const uri = `${process.env.REACT_APP_BACK_SERVICE}/auth/recover`
    fetch(uri, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if(data.statusCode){
          setError({state:true, message:data.message});
          return;
        }
        const {email} = data;
        return navigate('/Auth',{replace: true, state: email});
      })
      .catch((err) => {
        setError({state:true, message:init_error.message});
        console.log(err.message);
      });
  }

  const handleSubmit  = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    requestOptions = {
      method: 'POST',
      headers: [["Content-Type", "application/json"]],
      body: JSON.stringify({...useRecovery})
    };
    await fetchRecovery()
  };

  
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>{
    const target = e.target;
    const { name, value } = target;
    setRecovery((prevState) => ({ ...prevState, [name]: value}))
  }
  
  return{
    error,
    handleSubmit,
    onInputChange
  }

}